<template>
  <b-row class="pt-1">
    <b-col>
      <b-form-group v-slot="{ ariaDescribedby }" class="pt-2 mb-0">
        <b-form-radio-group
          id="period_options"
          v-model="value.period.field"
          :options="options"
          :aria-describedby="ariaDescribedby"
          name="period-options"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>
    <b-col>
      <date-picker
        id="period_from"
        v-model="value.period.from"
        :key="0"
        :format="datePickerConfig.format"
        :value-type="datePickerConfig['value-type']"
        placeholder="A partir de"
        :clearable="true"
        :lang="datePickerConfig.lang"
        class="full"
      />
    </b-col>
    <b-col>
      <date-picker
        id="period_to"
        v-model="value.period.to"
        :key="1"
        :format="datePickerConfig.format"
        :value-type="datePickerConfig['value-type']"
        placeholder="Até"
        :clearable="true"
        :lang="datePickerConfig.lang"
        class="full"
      />
    </b-col>

  </b-row>
</template>
<script>

import {datePickerConfig} from "@sp/config/selling-panel"

export default {
  name: "PeriodDate",
  props: {
    value: Object
  },

  computed: {},

  data() {
    return {
      options: [
        { value: 'created_at', text: 'Criação'},
        { value: 'expires_at', text: 'Validade'}
      ],
      selected: [],
      datePickerConfig
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style scoped lang="scss">

</style>